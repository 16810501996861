import Echo from 'laravel-echo';

window.io = require('socket.io-client');

export default (token) => {
    const echo = new Echo({
        broadcaster: 'socket.io',
        host: `${window.location.hostname}:${window._ENV.WEBSOCKET_SERVER_PORT}`,
    });

    const updateToken = token => {
        echo.connector.options.auth.headers['Authorization'] = `Bearer ${token}`;
    };
    updateToken(token);

    return [echo, updateToken];
};

