import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import {App, CriticalError} from '@bitsbeats/bb-horus-frontend';
import KeycloakLoader from "./KeycloakLoader";
import Echo from './services/WebSocket';

(async () => {
    try {
        await KeycloakLoader(async () => {
            const ApiClient = require('./services/ApiClient').default;
            const Keycloak = require('./services/Keycloak');
            await Keycloak.init();
            const [echo, updateToken] = await Echo(Keycloak.token);
            Keycloak.onRefresh(updateToken);
            let user = {};
            let error = false;
            try {
                user = await ApiClient.users.getMe();
            } catch (err) {
                error = err;
            }
            ReactDOM.render(<App
                apiClient={ApiClient}
                webSocket={echo}
                user={user}
                error={error}
            />, document.getElementById('root'));
        });
    } catch (Error) {
        ReactDOM.render(<CriticalError code="Keycloak"/>, document.getElementById('root'));
    }

    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    serviceWorker.unregister();
})();
