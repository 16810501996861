const KeycloakLoader = (callback) => {
    const scriptExists = document.getElementById('keycloak');

    if(!scriptExists) {
        const keycloak = document.createElement('script');
        keycloak.src = `${window._ENV.REACT_APP_AUTH_URL}/js/keycloak.js`;
        keycloak.id = 'keycloak';
        document.body.appendChild(keycloak);

        keycloak.onload = () => {
            if(callback) callback();
        };
    }

    if(scriptExists && callback) callback();
}

export default KeycloakLoader;